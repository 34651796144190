import styled from "styled-components"

export const Container = styled.div`
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  padding: 2rem 1rem;
  display: flex;
  gap: 1rem;

  .left-container {
    width: 100%;

    .certificate {
      border-radius: 8px;
      box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1),
        0 2px 4px -2px rgb(0 0 0 / 0.1);
      position: relative;
      z-index: 999;
      background: white;
    }

    .course-info {
      margin-top: 1rem;
      border-radius: 8px;
      box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1),
        0 2px 4px -2px rgb(0 0 0 / 0.1);
      height: 16rem;
      background: white;
    }
  }

  .right-container {
    width: 20rem;
    flex-shrink: 0;

    .user-card {
      background: white;
      padding: 2rem;
      border-radius: 8px;
      box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1),
        0 2px 4px -2px rgb(0 0 0 / 0.1);

      .pp-container {
        margin-left: auto;
        margin-right: auto;
        height: 6rem;
        width: 6rem;
        border-radius: 50%;
        overflow: hidden;

        .profile-picture {
          width: 100%;
        }
      }

      .user-name {
        text-align: center;
        margin: 1rem auto;
        padding: 0.5rem 1rem;
        background: #efa51d;
        border-radius: 4px;
        color: white;
      }

      .profile-section {
        text-align: center;
        margin-bottom: 1rem;

        .section-title {
          font-weight: 300;
        }

        .section-value {
          font-weight: 500;
        }
      }
    }
  }
`
