import React from "react"
import { Container } from "./styles"
import moment from "moment"
import { Document, Page, pdfjs } from "react-pdf"
import { apiURLV2 } from "../../services/utils"
import Button from "../Reusable/Button"

const CertificateComponent = ({ user, certificate }) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

  return (
    <Container>
      <div className="left-container">
        <div>
          <Document file={`${apiURLV2}/certificate/${certificate.id}/`}>
            <Page pageNumber={1} />
          </Document>
        </div>
        <br />
        <div>
          <Button
            onClick={() =>
              window.open(`${apiURLV2}/certificate/${certificate.id}`)
            }
          >
            Download PDF
          </Button>
        </div>
        {/* <div className="course-info"></div> */}
      </div>
      <div className="right-container">
        <div className="user-card">
          <div className="pp-container">
            <img
              src={certificate.user?.profile_picture}
              alt={
                certificate.user?.display_name || certificate.user?.usernamme
              }
              className="profile-picture"
            />
          </div>
          <div className="user-name">{certificate.name}</div>
          <div className="profile-section">
            <div className="section-title">ID</div>
            <div className="section-value" style={{ fontSize: "0.75rem" }}>
              {certificate?.id}
            </div>
          </div>
          <div className="profile-section">
            <div className="section-title">Diberikan pada</div>
            <div className="section-value">
              {moment(certificate?.created_at).format("DD MMM YYYY")}
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default CertificateComponent
