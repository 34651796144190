import React, { useEffect, useState } from "react"
import CertificateComponent from "../../components/Certificate"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { getCourseCertificate } from "../../services/course"
import * as sessionSelectors from "../../redux/slices/session/selectors"
import { connect } from "react-redux"
import Spinner from "../../components/Reusable/Spinner"

const mapStateToProps = state => ({
  user: sessionSelectors.getUser(state),
})

const mapDispatchToProps = {}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const Sertifikat = ({ params, user }) => {
  const [certificate, setCertificate] = useState({})

  useEffect(() => {
    getCourseCertificate(params.id).then(resp => {
      setCertificate(resp.data)
    })
  }, [params.id])

  return (
    <Layout bgColor={"#F4F4F4"}>
      <SEO title="Sertifikat Penyelesaian" />
      {certificate.id ? (
        <CertificateComponent user={user} certificate={certificate} />
      ) : (
        <div
          style={{
            width: "100%",
            height: "90vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spinner />
        </div>
      )}
    </Layout>
  )
}

export default withConnect(Sertifikat)
